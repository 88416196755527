import React from "react"
import { graphql } from "gatsby"

import Image from "components/utils/Image"
import Link from "components/utils/Link"

import Flex from "components/atoms/Flex"

const IconLink = ({ icon, href, height, width, ...rest }) => {
  return (
    <Link href={href} {...rest}>
      <Flex
        width={width !== undefined ? width : "20px"}
        height={height !== undefined ? height : "20px"}
        alignItems="center"
        justifyContent="center"
      >
        <Image {...icon} style={{ width: "100%" }} />
      </Flex>
    </Link>
  )
}

export default IconLink

export const query = graphql`
  fragment IconLink on ContentfulIconLink {
    icon {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    href
  }
`
