import React from "react"
import { graphql } from "gatsby"

import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import Icon from "components/atoms/Icon"
import { Paragraph } from "components/atoms/Typography"
import Button from "components/atoms/Button"

const LinkButton = props => {
  return (
    <Button
      variant="link"
      {...props}
      textColor="text"
      fontSize={props.fontSize || 2}
    />
  )
}

const NavDropdownLink = ({
  title,
  description,
  page,
  sublinks,
  closeDropdown,
  fontSize,
  arrow = false,
}) => {
  //console.log("navDropDownLinks: ", sublinks)

  let font_size = "default"
  if (fontSize === "smaller") {
    font_size = "14px"
  }
  return (
    <Flex mb={fontSize === "smaller" ? 3 : 4}>
      {/* <Icon {...icon} size="large" /> */}
      <Box>
        <LinkButton
          text={title}
          link={{ to: page }}
          onClick={closeDropdown}
          fontSize={fontSize}
          showArrow={arrow}
          arrowColor="gray"
        />
        {description && <Paragraph mt={2}>{description}</Paragraph>}
        {sublinks && (
          <Box mt={6}>
            {sublinks.map((sublink, i) => {
              return (
                <>
                  <LinkButton
                    text={sublink.title}
                    link={{ to: sublink.page }}
                    mt={4}
                    onClick={closeDropdown}
                    key={i}
                    fontSize={fontSize}
                    showArrow={arrow}
                    arrowColor="gray"
                  />
                  {sublink.description && (
                    <Paragraph mt={2}>{sublink.description}</Paragraph>
                  )}
                </>
              )
            })}
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default NavDropdownLink

// export const query = graphql`
//   fragment NavDropdownLink on ContentfulNavDropdownLink {
//     title
//     description
//     icon {
//       ...Icon
//     }
//     page {
//       slug
//     }
//     sublinks {
//       title
//       description
//       page {
//         slug
//       }
//     }
//   }
// `
