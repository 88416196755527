import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    }

    * {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
    }
    a:visited {
        color: inherit;
    }

    button {
        padding: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        background-color: transparent;
}

    figure {
        margin: 0;
    }

    .react-reveal {
        height: 100%;
    }
`

export default GlobalStyle
