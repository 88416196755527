import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import css from "@styled-system/css"
import { color, space, flexbox, layout, compose } from "styled-system"
import { animation } from "lib/styled-system/system"

import CSSTransition from "components/utils/CSSTransition"
import Image from "components/utils/Image"
import Link from "components/utils/Link"

import MorphicBadge from "components/molecules/MorphicBadge"

import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { Text } from "components/atoms/Typography"
import StandardPadding from "components/atoms/StandardPadding"
import NavLink from "components/atoms/NavLink"
import IconLink from "components/atoms/IconLink"

import SmallArrowGrey from "components/icons/SmallArrowGrey"

const StyledFooter = styled("footer")(
  css({
    minHeight: 6,
    height: "225px",
  }),
  color
)

const StyledButton = styled("button")`
  ${compose(space, flexbox, layout)}
  padding: 0;
  margin: 0;
`

const StyledBox = styled(Box)`
  transition-property: transform, opacity;
  ${animation}
  &.dropdown-mobile-enter {
    transform: translateY(-5%);
    opacity: 0;
  }
  &.dropdown-mobile-enter-active {
    transform: translateY(0%);
    opacity: 1;
  }
  &.dropdown-mobile-enter-done {
    transform: translateY(0%);
    opacity: 1;
  }
  &.dropdown-mobile-exit {
    display: none;
  }
  &.dropdown-mobile-exit-active {
    display: none;
  }
  &.dropdown-mobile-exit-done {
    display: none;
  }
`

const onHoverEnter = e => {
  e.target.style.color = "#4163F4"
}
const onHoverLeave = e => {
  e.target.style.color = "black"
}

const Footer = ({ primaryLinks, secondaryLinks, socialLinks }) => {
  return (
    <StyledFooter bg="background.0">
      <StandardPadding py={0} pt={{ _: 8, lg: 7 }}>
        <Flex>
          <Flex
            width={{ _: "100%", lg: "50%" }}
            justifyContent={{ _: "center", lg: "space-between" }}
            width={"100%"}
            height={"100%"}
            flexDirection={{ _: "column", lg: "row" }}
          >
            <Flex
              flexDirection={"column"}
              alignItems={{ _: "center", lg: "flex-start" }}
            >
              {/* for mobile: */}
              <Flex
                pb={4}
                pr={{ _: 0, lg: 11 }}
                justifyContent={{ _: "center", lg: "flex-start" }}
                flexDirection={{ _: "column", lg: "row" }}
                width={"100%"}
                display={{ _: "flex", lg: "none" }}
              >
                {primaryLinks.map((link, i) => {
                  switch (link.__typename) {
                    case "ContentfulDropdownLinks":
                      return (
                        <Box my={3} pr={4}>
                          <FooterDropdownButton
                            title={link.dropdownLinkText}
                            sublinks={link.sublinks}
                          />
                        </Box>
                      )
                    default:
                      return (
                        <Box my={3}>
                          <NavLink
                            {...link}
                            key={i}
                            display="inline"
                            mb={i === primaryLinks.length - 1 ? 0 : 5}
                            fontSize={"18px"}
                            fontWeight="regular"
                            pr={4}
                            mb={4}
                          />
                        </Box>
                      )
                  }
                })}
              </Flex>
              {/* for desktop: */}
              <Box display={{ _: "none", lg: "block" }} pb={4}>
                <Flex flexDirection={"row"}>
                  <Flex
                    pr={4}
                    justifyContent={{ _: "center", lg: "flex-start" }}
                    flexDirection={{ _: "column" }}
                    maxWidth={"120px"}
                  >
                    {primaryLinks.map((link, i) => {
                      switch (link.__typename) {
                        default:
                          return (
                            <Box my={3}>
                              <NavLink
                                {...link}
                                onMouseEnter={onHoverEnter}
                                onMouseLeave={onHoverLeave}
                                key={i}
                                mb={i === primaryLinks.length - 1 ? 0 : 5}
                                fontSize={"18px"}
                                fontWeight="regular"
                                pr={4}
                                mb={4}
                              />
                            </Box>
                          )
                      }
                    })}
                  </Flex>
                  {primaryLinks.map((link, i) => {
                    switch (link.__typename) {
                      case "ContentfulDropdownLinks":
                        return (
                          <Box my={3} pr={4}>
                            <FooterDropdownButton
                              title={link.dropdownLinkText}
                              sublinks={link.sublinks}
                              overrideOpen={true}
                            />
                          </Box>
                        )
                    }
                  })}
                </Flex>
              </Box>
              {/* end desktop  */}
              {/* secondary/social links mobile start */}
              <Flex
                pb={4}
                pr={{ _: 0, lg: 11 }}
                justifyContent={{ _: "center", lg: "flex-start" }}
                display={{ _: "flex", lg: "none" }}
              >
                {secondaryLinks.map((link, i) => (
                  <NavLink
                    {...link}
                    key={i}
                    display="inline"
                    mb={5}
                    fontSize={"12px"}
                    fontWeight="regular"
                    pr={4}
                  />
                ))}
              </Flex>
              <Flex
                height={"38px"}
                mb={{ _: 4, lg: 0 }}
                display={{ _: "flex", lg: "none" }}
              >
                {socialLinks.map((link, i) => (
                  <IconLink
                    {...link}
                    mr={i !== 0 ? "0px" : "75px"}
                    key={i}
                    height={"38px"}
                    width={"38px"}
                  />
                ))}
              </Flex>
            </Flex>
            <Flex
              flexDirection={"column"}
              alignItems={{ _: "center", lg: "start" }}
              maxWidth={"225px"}
              mb={6}
            >
              {/* desktop secondary/social links start */}
              <Box display={{ _: "none", lg: "block" }}>
                <Flex
                  // border={"red 1px solid"}
                  height={"28px"}
                  mb={{ _: 6, lg: 4 }}
                  justifyContent={"start"}
                >
                  {socialLinks.map((link, i) => {
                    //NavLink Not working for desktop, recreating with less divs
                    return (
                      <>
                        <Link showDisclosure={false} href={link.href}>
                          <Image
                            fluid={link.icon.fluid}
                            style={{
                              width: "28px",
                              height: "28px",
                              marginRight: `${i !== 0 ? "0px" : "15px"}`,
                              marginTop: "5px",
                            }}
                          />
                        </Link>
                      </>
                    )
                  })}
                </Flex>
                <Flex
                  pb={4}
                  flexDirection={"column"}
                  justifyContent={"flex-end"}
                >
                  {secondaryLinks.map((link, i) => {
                    return (
                      <Box pb={4}>
                        <NavLink
                          {...link}
                          onMouseEnter={onHoverEnter}
                          onMouseLeave={onHoverLeave}
                          key={i}
                          display="inline"
                          mb={5}
                          fontSize={"12px"}
                          fontWeight="regular"
                        />
                      </Box>
                    )
                  })}
                </Flex>
              </Box>
              <Text
                //maxWidth={{ _: undefined, lg: "240px" }}
                fontSize={"14px"}
                pb={4}
                fontWeight="regular"
              >
                © {new Date().getFullYear()} Richard Bernstein Advisors LLC. All
                rights reserved.
              </Text>
              <MorphicBadge />
            </Flex>
          </Flex>
        </Flex>
      </StandardPadding>
    </StyledFooter>
  )
}

export default Footer

const FooterDropdownButton = ({
  title,
  dropdownLinks,
  closeDropdown,
  openDropdown,
  active,
  sublinks,
  overrideOpen,
  ...rest
}) => {
  const [menuActive, setMenuActive] = useState(false)

  return (
    <>
      <StyledButton
        display="flex"
        alignItems="center"
        justifyContent={"flex-start"}
        onClick={overrideOpen ? undefined : () => setMenuActive(!menuActive)}
        style={overrideOpen && { cursor: "default" }}
      >
        <Text fontSize={"18px"} fontWeight={"regular"}>
          {title}
        </Text>
      </StyledButton>
      <CSSTransition
        in={overrideOpen ? overrideOpen : menuActive}
        timeout="fast"
        classNames="dropdown-mobile"
        mountOnEnter
        unmountOnExit
      >
        <StyledBox transitionDuration="fast" transitionTimingFunction="ease-in">
          <Box ml={4}>
            {sublinks?.map((sublink, i) => {
              switch (sublink.__typename) {
                case "ContentfulDropdownLinks":
                  return (
                    <>
                      <Box my={4} key={i}>
                        <NavLink
                          {...sublink}
                          onMouseEnter={onHoverEnter}
                          onMouseLeave={onHoverLeave}
                          hrefText={sublink.dropdownLinkText}
                          display="inline"
                          fontSize={"14px"}
                          fontWeight="regular"
                        />
                      </Box>
                      {sublink.sublinks.map((item, j) => {
                        return (
                          <Box ml={4} my={4} key={j}>
                            <NavLink
                              {...item}
                              onMouseEnter={onHoverEnter}
                              onMouseLeave={onHoverLeave}
                              key={i}
                              display="inline"
                              fontSize={"11px"}
                              fontWeight="regular"
                              pr={3}
                            />
                            <SmallArrowGrey />
                          </Box>
                        )
                      })}
                    </>
                  )
                default:
                  return (
                    <Box my={4} key={i}>
                      <NavLink
                        {...sublink}
                        onMouseEnter={onHoverEnter}
                        onMouseLeave={onHoverLeave}
                        key={i}
                        display="inline"
                        fontSize={"14px"}
                        fontWeight="regular"
                      />
                    </Box>
                  )
              }
            })}
          </Box>
        </StyledBox>
      </CSSTransition>
    </>
  )
}

export const query = graphql`
  fragment Footer on ContentfulFooter {
    __typename
    secondaryLinks {
      ...Link
    }
    socialLinks {
      ...IconLink
    }
    primaryLinks {
      ...Link
      ... on ContentfulDropdownLinks {
        dropdownLinkText
        sublinks {
          ...Link
          ... on ContentfulDropdownLinks {
            dropdownLinkText
            to {
              slug
              link {
                ...Link
              }
            }
            sublinks {
              ...Link
            }
          }
        }
      }
    }
  }
`
