import React from "react"
import Svg from "components/utils/Svg"
import Path from "components/utils/Path"

const SmallArrowGrey = ({ stroke, direction, ...rest }) => {
  let deg = 0
  switch (direction) {
    case "left":
      deg = 90
      break
    case "up":
      deg = 180
      break
    case "right":
      deg = 270
      break
    default:
      deg = 0
  }
  return (
    // <Svg
    //   {...rest}
    //   width="12"
    //   height="9"
    //   viewBox="0 0 12 9"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g fill="none" transform={`rotate(${deg},6,4)`}>
    //     <Path d="M.75.75h10v7h-10v-7z" />
    //     <Path
    //       d="M2.75 2.75l3 3 3-3"
    //       stroke={stroke || "primary"}
    //       strokeWidth="1.5"
    //       strokeLinecap="square"
    //     />
    //   </g>
    // </Svg>
    <>
      <Svg
        width="20px"
        height="20px"
        viewBox="0 0 13 8"
        //version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        //xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>Group 5</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="square"
        >
          <g
            id="Home-Copy"
            transform="translate(-366.000000, -3184.000000)"
            stroke="#9297b1"
          >
            <g id="Group-5" transform="translate(367.000000, 3185.000000)">
              <line
                x1="9"
                y1="2.53125"
                x2="9"
                y2="2.53125"
                id="Line-7"
                stroke-width="0.5625"
              ></line>
              <line
                x1="-4.09272616e-12"
                y1="2.8125"
                x2="9"
                y2="2.8125"
                id="Line-8"
                stroke-width="1.125"
              ></line>
              <polyline
                id="Line-9"
                stroke-width="1.125"
                points="7.21875 5.625 10.03125 2.8125 7.21875 6.39550912e-13"
              ></polyline>
            </g>
          </g>
        </g>
      </Svg>
    </>
  )
}

export default SmallArrowGrey
