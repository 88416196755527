// Default constants that can be overwritten by the theme

// Breakpoint definitions using the same breakpoints as TailwindCSS
// https://tailwindcss.com/docs/breakpoints/
// https://styled-system.com/responsive-styles
const breakpoints = ["425px", "640px", "768px", "1024px", "1280px"]
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

// Margin and padding values
// https://styled-system.com/api#space
const space = [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
  16, // 4
  24, // 5
  32, // 6
  48, // 7
  64, // 8
  80, // 9
  96, // 10
  112, // 11
  128, // 12
  256, // 13
  512, // 14
]

// Height and width - https://styled-system.com/api#layout
// prettier-ignore
const sizes = [
  0, 16, 32, 48, 64, 80, 96, 112, 128, 144, 160, 176, 192, 208, 224, 240, 
  256, 272, 288, 304, 320, 336, 352, 368, 384, 400, 416, 432, 448, 464, 
  480, 496, 512, 528, 544, 560, 576, 592, 608, 624, 640, 656, 672, 688, 
  704, 720, 736, 752, 768, 784, 800, 816, 832, 848, 864, 880, 896, 912, 
  928, 944, 960, 976, 992, 1008, 1024, 1040, 1056, 1072, 1088, 1104, 1120,
]

export const defaultButtonVariants = {
  primary: {
    color: "textOnPrimary",
    bg: "primary",
    borderRadius: "24px",
    fontWeight: "semibold",
    px: "18px",
    height: "40px",
  },
  secondarySquare: {
    color: "textOnSecondary",
    bg: "secondary",
    borderRadius: "0px",
    fontWeight: "semibold",
    px: "18px",
    height: "40px",
  },
  filled: {
    color: "textOnPrimary",
    bg: "primary",
    borderRadius: "24px",
    fontWeight: "semibold",
    px: "18px",
    height: "40px",
  },
  white: {
    color: "primary",
    bg: "white",
    borderRadius: "24px",
    fontWeight: "semibold",
    px: "18px",
    height: "40px",
  },
  inverted: {
    color: "primary",
    bg: "background.1",
    borderRadius: "24px",
    fontWeight: "semibold",
    paddingX: "18px",
    height: "40px",
  },
  outlined: {
    color: "primary",
    bg: "transparent",
    borderColor: "primary",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "24px",
    fontWeight: "semibold",
    paddingX: "18px",
    height: "40px",
  },
  link: {
    color: "primary",
    fontWeight: "semibold",
  },
}

export const defaultTypographyVariants = {
  h1: {
    fontFamily: "heading",
    lineHeight: "heading",
    fontSize: 5,
  },
  h2: {
    fontFamily: "heading",
    lineHeight: "heading",
    fontSize: 4,
  },
  h3: {
    fontFamily: "heading",
    lineHeight: "heading",
    fontSize: 3,
    fontWeight: "medium",
  },
  p: {
    fontFamily: "body",
    lineHeight: "body",
    fontSize: 1,
    fontWeight: "regular",
  },
  label: {
    fontFamily: "body",
    lineHeight: "body",
    letterSpacing: "0.1em",
    fontSize: 0,
    textTransform: "uppercase",
    fontWeight: "semibold",
  },
  text: {
    fontFamily: "body",
    fontSize: 2,
    fontWeight: "semibold",
  },
}

// Default colors to use in the theme.
export const colors = {
  white: "#fff",
  "light-bg-1": "#F1F2FA",
  "light-bg-2": "#F0F2FA",
  accent: "#4163F4",
  "accent-2": "#0020A2",
  dark: "#000E46",
  "light-secondary-text": "#8188A4",
  "highlight-bg-1": "#1737BE",
  "dark-bg-1": "#202B5A",
  "dark-bg-2": "#4B67D3",
  black: "#000",
  shadow: "rgba(0,32,162,0.15)",
  gray: "#9EA0A6",
}

//Somewhat based on the theme specification of Theme UI
//https://theme-ui.com/theme-spec
export const defaultTheme = {
  colors: {
    text: colors.dark,
    gray: colors.gray,
    background: [
      colors.white,
      colors["light-bg-1"],
      colors["light-bg-2"],
      colors["light-bg-3"],
    ],
    primary: colors.primary,
    textOnPrimary: colors.white,
    border: ["#b0b6c0", "#ced3ec"],
  },
  fonts: {
    heading: "'Nunito Sans', sans-serif",
    body: "'Nunito Sans', sans-serif",
  },
  fontSizes: [11, 15, 16, 18, 24, 32, 48],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    heading: "1.3em",
    body: "1.47em",
  },
  typography: defaultTypographyVariants,
  radii: {
    sm: 6,
    md: 10,
    lg: 24,
  },
  shadows: {
    sm: `0 5px 10px ${colors.shadow}`,
    md: `0 6px 25px ${colors.shadow}`,
    lg: `0 5px 35px ${colors.shadow}`,
  },
  buttons: defaultButtonVariants,
  space,
  sizes,
  breakpoints,
  durations: {
    fast: 200,
    md: 500,
    slow: 1000,
  },
  timingFunctions: {
    ease: "ease",
    "ease-in": "ease-in",
    "ease-out": "ease-out",
    "ease-in-out": "ease-in-out",
  },
  reveal: {
    effect: "fade-up",
    duration: "slow",
    timingFunction: "ease",
  },
  themes: {
    dark: {
      colors: {
        primary: colors.accent,
        secondary: colors["accent-2"],
        text: colors.white,
        textOnPrimary: colors.white,
        textOnSecondary: colors.white,
        background: [
          colors["dark-bg-1"],
          colors["dark-bg-2"],
          colors["accent-2"],
          colors["accent"],
        ],
        lightText: colors.gray,
        newsletterLabel: colors.white,
      },
    },
    light: {
      colors: {
        primary: colors.accent,
        secondary: colors["accent-2"],
        text: colors.dark,
        textOnPrimary: colors.white,
        textOnSecondary: colors.white,
        background: [colors.white, colors["light-bg-1"], colors["light-bg-2"]],
        lightText: colors.gray,
        newsletterLabel: colors.accent,
      },
    },
    highlight: {
      colors: {
        primary: colors.white,
        secondary: colors.accent,
        text: colors.white,
        textOnPrimary: colors.accent,
        textOnSecondary: colors.white,
        background: [
          colors.accent,
          colors["highlight-bg-1"],
          colors["accent-2"],
        ],
        lightText: colors.white,
        newsletterLabel: colors.white,
      },
    },
  },
}
