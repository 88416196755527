import React from "react"
import Path from "components/utils/Path"

const Close = ({ fill }) => {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <Path
        fill={fill || "primary"}
        d="M1.414 0l17.678 17.678-1.414 1.414L0 1.414 1.414 0z"
      />
      <Path
        fill={fill || "primary"}
        d="M19.092 1.414L1.414 19.092 0 17.678 17.678 0l1.414 1.414z"
      />
    </svg>
  )
}

export default Close
