import React, { useState } from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import { space, color, border, typography } from "styled-system"

import { Title, Label, Paragraph, Text } from "components/atoms/Typography"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"

import RichText from "components/molecules/RichText"

import CloseIcon from "components/icons/close-icon-x.svg"

// Apply margin and padding to links
var StyledGatsbyLink = null
if (GatsbyLink) {
  StyledGatsbyLink = styled(GatsbyLink)(space)
}

const Anchor = styled("a")(space, color, border, typography)

const StyledAnchor = styled(Anchor)`
  color: black;
  :hover {
    cursor: pointer;
  }
`

const modalBackground = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const modalPopup = keyframes`
  0% {
    transform: translateY(10vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
`

const StyledDialogOverlay = styled(DialogOverlay)`
  background-color: rgba(28, 39, 77, 0.9);
  z-index: 1000;
  will-change: opacity;
  animation: ${modalBackground} 0.3s cubic-bezier(0, 0.77, 0.28, 0.99);
`

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  width: 90vw;
  max-width: 500px;
  @media (min-width: 768px) {
    width: 90vw;
    max-width: 960px;
  }
  will-change: top, opacity;
  animation: ${modalPopup} 0.3s cubic-bezier(0, 0.77, 0.28, 0.99);
`
/*
 * Switches between a Gatsby link and a regular anchor tag based on props
 * The "to" prop is the pageId of an internal page to link to
 */
const Link = ({
  to,
  sectionId,
  href,
  hrefText,
  indexPageSlug,
  children,
  showDisclosure = true,
  disclosureText,
  ...rest
}) => {
  const [showDialog, setShowDialog] = useState(false)
  if (href?.slice(-4) === ".pdf") {
    showDisclosure = false
  }
  if (to) {
    // Contentful alteration
    let pagePath
    if (typeof to === "string") {
      pagePath = to
    } else if (to.__typename === "ContentfulPage") {
      if (to.slug === indexPageSlug || to.slug === "/") {
        pagePath = "/"
      } else {
        pagePath = `/${to.slug}/`
      }
    } else if (to.__typename === "ContentfulArticle") {
      pagePath = `/${to.location}/${to.slug}/`
    } else if (to.slug) {
      pagePath = `/${to.slug}/`
    }

    if (sectionId) {
      pagePath = pagePath + "#" + sectionId
    }

    if (pagePath) {
      return (
        <StyledGatsbyLink to={pagePath} {...rest}>
          {children}
        </StyledGatsbyLink>
      )
    }
  }

  if (href) {
    // Always open external links in a new tab
    return (
      <>
        <Box
          onClick={() => {
            showDisclosure && setShowDialog(true)
          }}
        >
          <StyledAnchor
            rel="noopener"
            href={!showDisclosure ? href : null}
            target={"_blank"}
            {...rest}
          >
            {children}
          </StyledAnchor>
        </Box>
        <StyledDialogOverlay
          isOpen={showDialog}
          onDismiss={() => setShowDialog(false)}
        >
          <StyledDialogContent>
            <Box bg="white" boxShadow="lg">
              <Flex justifyContent={"flex-end"} pt={5} pr={5} bg={"white"}>
                <CloseIcon
                  onClick={() => setShowDialog(false)}
                  style={{ cursor: "pointer" }}
                />
              </Flex>
              <Flex
                flexDirection={{ _: "column-reverse", md: "row" }}
                px={{ _: 6, md: 8 }}
                pb={6}
              >
                <Box mt={{ _: 5, md: 0 }}>
                  <Title color={"black"}>
                    You Are About To Leave RBA's Website
                  </Title>
                  <RichText
                    textColor={"black"}
                    mt="20px"
                    text={disclosureText}
                  />
                </Box>
              </Flex>
              <Flex
                flexDirection={"row"}
                px={{ _: 6, md: 8 }}
                pb={8}
                justifyContent={"start"}
              >
                <StyledAnchor
                  style={{ paddingRight: "20px" }}
                  onClick={() => setShowDialog(false)}
                  py={3}
                  pr={3}
                >
                  Return
                </StyledAnchor>
                <StyledAnchor
                  onClick={() => setShowDialog(false)}
                  href={href}
                  target={"_blank"}
                >
                  <Box
                    border={"#4163F4 1px solid"}
                    borderRadius={4}
                    p={3}
                    bg={"#4163F4"}
                  >
                    <Text color={"white"}>Continue</Text>
                  </Box>
                </StyledAnchor>
              </Flex>
            </Box>
          </StyledDialogContent>
        </StyledDialogOverlay>
      </>
    )
  }

  return <>{children}</>
}

export default props => (
  <StaticQuery
    query={graphql`
      query LinkIndexPageQuery {
        contentfulSite {
          indexPage {
            slug
          }
        }
      }
    `}
    render={data => (
      <Link indexPageSlug={data.contentfulSite.indexPage.slug} {...props} />
    )}
  />
)

Link.strapiProps = {
  to: PropTypes.oneOfType([
    PropTypes.string, //Exact string path
    PropTypes.object, // Object with page data
  ]),
  href: PropTypes.string, // Url of external site
  hrefText: PropTypes.string,
}

Link.propTypes = {
  ...Link.strapiProps,
  indexPageSlug: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  ...propTypes.space,
}

export const query = graphql`
  fragment Link on ContentfulLink {
    to {
      ... on ContentfulPage {
        __typename
        slug
      }
      ... on ContentfulArticle {
        __typename
        slug
        location
      }
    }
    href
    hrefText
  }
`
