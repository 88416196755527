import React, { useContext } from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled, { ThemeContext } from "styled-components"
import {
  typography,
  space,
  color,
  layout,
  variant,
  compose,
  flexbox,
} from "styled-system"
import css from "@styled-system/css"
import { shouldForwardProp } from "lib/styled-system/helpers.js"
import { defaultTypographyVariants } from "lib/styled-system/constants"

const Typography = styled("span").withConfig({
  shouldForwardProp,
  displayName: "Typography",
})`
  margin: 0;
  ${css({
    fontWeight: "regular",
    color: "text",
    a: {
      color: "primary",
    },
  })}
  ${variant({
    scale: "typography",
    variants: defaultTypographyVariants,
  })}
  ${compose(typography, space, color, layout, flexbox)}
`

/**
 * Title
 */
export const Title = ({ children, variant, as, ...rest }) => {
  const theme = useContext(ThemeContext)
  const fontSize = theme.typography[variant].fontSize
  return (
    <Typography
      variant={variant}
      fontSize={variant === "h3" ? fontSize : { _: fontSize - 1, sm: fontSize }}
      as={as ? as : variant}
      {...rest}
    >
      {children}
    </Typography>
  )
}

Title.propTypes = {
  variant: PropTypes.oneOf(["h1", "h2", "h3"]).isRequired,
  as: PropTypes.oneOf(["h1", "h2", "h3"]),
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

Title.defaultProps = {
  variant: "h2",
}

/**
 * Paragraph
 */
export const Paragraph = ({ children, ...rest }) => {
  return (
    <Typography as="p" variant="p" {...rest}>
      {children}
    </Typography>
  )
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * Label
 */
export const Label = ({ children, ...rest }) => {
  return (
    <Typography as="div" variant="label" {...rest}>
      {children}
    </Typography>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * Text
 */
export const Text = ({ children, ...rest }) => {
  return (
    <Typography as="span" variant="text" {...rest}>
      {children}
    </Typography>
  )
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}
