import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { defaultTheme } from "lib/styled-system/constants"
import ReactHtmlParser from "react-html-parser"

import HeaderDefault from "components/headers/Header"
import Footer from "components/footers/Footer"
import GlobalStyle from "components/utils/GlobalStyle"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    header: "[data-scroll-header]",
  })
}

const Layout = ({
  site,
  children,
  headHtml,
  bodyHtml,
  firstSectionTheme,
  pageSlug,
}) => {
  return (
    <>
      <Helmet>
        <noscript>
          {/* If there's no Javascript, show all react-reveal elements */}
          {`<style>.react-reveal {visibility: visible !important;}</style>`}
        </noscript>
        {headHtml && ReactHtmlParser(headHtml)}
      </Helmet>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <HeaderDefault
          siteTitle={site.title}
          {...site.header}
          firstSectionTheme={firstSectionTheme}
          pageSlug={pageSlug}
        />
        <main>{children}</main>
        <Footer {...site.footer} />
      </ThemeProvider>
      {bodyHtml && ReactHtmlParser(bodyHtml)}
    </>
  )
}

Layout.propTypes = {
  site: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
