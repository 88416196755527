import styled from "styled-components"
import css from "@styled-system/css"
import { space } from "styled-system"

export const UnorderedList = styled("ul")`
  margin: 0;
  ${css({ paddingLeft: 6 })}
  ${space}
`

export const OrderedList = styled("ol")`
  margin: 0;
  ${css({ paddingLeft: 6 })}
  ${space}
`

export const ListItem = styled("li")`
  ${css({ paddingLeft: 3, marginBottom: 4 })}
  & p {
    margin-bottom: 0;
  }
`
