import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import {
  color,
  space,
  layout,
  typography,
  border,
  compose,
  position,
  variant,
} from "styled-system"
import css from "@styled-system/css"
import { splitMarginProps } from "lib/styled-system/helpers"
import { defaultButtonVariants } from "lib/styled-system/constants"

import { Text } from "components/atoms/Typography"
import Link from "components/utils/Link"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import Icon from "components/atoms/Icon"

import LinkArrow from "components/icons/LinkArrow"

const StyledButton = styled("button")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
  &:hover {
    & span {
      color: #4163F4;
    }
  }
  ${css({
    fontWeight: "medium",
    borderRadius: "sm",
    ".arrow": {
      transition: "transform 0.2s ease",
    },
    "&:hover .arrow": {
      transform: "translateX(5px)",
    },
  })}
  ${variant({
    scale: "buttons",
    variants: defaultButtonVariants,
  })}
  ${compose(space, color, layout, typography, position, border)}
`

const StyledInput = styled("button")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
  &:hover {
    color: "black";
    opacity: .7;
  }
  ${css({
    fontWeight: "medium",
    borderRadius: "sm",
    ".arrow": {
      transition: "transform 0.2s ease",
    },
    "&:hover .arrow": {
      transform: "translateX(5px)",
    },
  })}
  ${variant({
    scale: "buttons",
    variants: defaultButtonVariants,
  })}
  ${compose(space, color, layout, typography, position, border)}
`

/*
 * Button component
 * Variants "default" and "primary" are defined in styled-system theme
 * There is also a "link" variant that turns the button into a regular text link
 *
 */
const Button = ({
  text,
  variant = "filled",
  link,
  noArrow,
  children,
  buttonColor,
  textColor,
  arrowColor,
  showArrow,
  type,
  showDisclosure,
  disclosureText,
  ...rest
}) => {
  // If the button is actually a link
  const linkVariant = variant === "link"
  let buttonProps = {
    color: textColor,
    bg: variant === "filled" ? buttonColor : undefined,
    borderColor: variant === "outlined" ? buttonColor : undefined,
  }

  let variantProps = {}
  if (linkVariant) {
    variantProps.width = "auto"
    variantProps.height = "auto"
    variantProps.minWidth = "auto"
  }

  const arrow = linkVariant
    ? typeof showArrow === "boolean"
      ? showArrow
      : true
    : showArrow

  let finalArrowColor = arrowColor
  if (!finalArrowColor) {
    switch (variant) {
      case "inverted":
        finalArrowColor = "primary"
        break
      case "primary":
      case "filled":
        finalArrowColor = "white"
        break
      default:
        finalArrowColor = "primary"
    }
  }

  if (type === "input") {
    return (
      <StyledInput
        variant={variant}
        {...buttonProps}
        {...variantProps}
        {...rest}
      >
        <Text color="inherit" fontWeight="inherit" mr={arrow && 3}>
          {children || text}
        </Text>
        {arrow && <LinkArrow color={finalArrowColor} className="arrow" />}
      </StyledInput>
    )
  }

  if (link) {
    // Split up margin props to apply to box outside of the link
    const { margin, rest: buttonPropsRest } = splitMarginProps(rest)
    buttonProps = { ...buttonProps, ...buttonPropsRest }

    return (
      <Box {...margin} style={{ whiteSpace: "no-wrap" }}>
        <Link
          disclosureText={disclosureText}
          showDisclosure={showDisclosure}
          {...link}
        >
          {linkVariant && (
            <StyledButton
              as="div"
              variant={variant}
              {...buttonProps}
              {...variantProps}
            >
              <Flex alignItems="center">
                <Text
                  color={textColor ? textColor : "#4163F4"}
                  fontWeight="inherit"
                  fontSize="inherit"
                  mr={arrow && 3}
                >
                  {children || text}
                </Text>
                {arrow && (
                  <LinkArrow color={finalArrowColor} className="arrow" />
                )}
              </Flex>
            </StyledButton>
          )}
          {!linkVariant && (
            <StyledButton
              as="div"
              variant={variant}
              {...buttonProps}
              {...variantProps}
            >
              <Flex>
                <Text
                  color="inherit"
                  fontWeight="inherit"
                  fontSize="inherit"
                  mr={arrow && 3}
                >
                  {children || text}
                </Text>
                {arrow && (
                  <LinkArrow color={finalArrowColor} className="arrow" />
                )}
              </Flex>
            </StyledButton>
          )}
        </Link>
      </Box>
    )
  }

  // Else it's a real button with an onClick function
  return (
    <StyledButton
      variant={variant}
      {...buttonProps}
      {...variantProps}
      {...rest}
    >
      <Text color="inherit" fontWeight="inherit" mr={arrow && 3}>
        {children || text}
      </Text>
      {arrow && <LinkArrow color={finalArrowColor} className="arrow" />}
    </StyledButton>
  )
}

export default Button

Button.strapiProps = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

Button.propTypes = {
  ...Button.strapiProps,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

Button.defaultProps = {
  variant: "primary",
}

export const query = graphql`
  fragment Button on ContentfulButton {
    text
    variant
    link {
      ...Link
    }
    showArrow
    buttonColor
    textColor
  }
`
