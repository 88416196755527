import React, { useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { space, flexbox, layout, compose } from "styled-system"
import { animation } from "lib/styled-system/system"
import css from "@styled-system/css"

import CSSTransition from "components/utils/CSSTransition"

import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import { Text } from "components/atoms/Typography"
import StandardPadding from "components/atoms/StandardPadding"
import NavDropdownLink from "components/molecules/NavDropdownLink"

import SmallArrow from "components/icons/SmallArrow"
import { Fragment } from "react"

const StyledButton = styled("button")`
  ${compose(space, flexbox, layout)}
`

const DropdownContainer = styled(Flex)`
  ${animation}
  &.dropdown-enter {
    /* transform: translateY(-100%); */
    opacity: 0;
  }
  &.dropdown-enter-active {
    /* transform: translateY(0%); */
    opacity: 1;
  }
  &.dropdown-enter-done {
    /* transform: translateY(0%); */
    opacity: 1;
  }
  &.dropdown-exit {
    /* transform: translateY(0%); */
    opacity: 1;
  }
  &.dropdown-exit-active {
    /* transform: translateY(-100%); */
    opacity: 0;
  }
  &.dropdown-exit-done {
    /* transform: translateY(-100%); */
    opacity: 0;
  }
`

const DropdownMenu = styled(Box)`
  position: absolute;
  padding: 32px 32px;
  background-color: #fff;
  transition-property: transform, opacity;
  ${css({
    bg: "background.0",
    boxShadow: "lg",
    top: "90px",
  })}
`

const DropdownMobile = styled("div")`
  transition-property: transform, opacity;
  ${animation}
  &.dropdown-mobile-enter {
    transform: translateY(-5%);
    opacity: 0;
  }
  &.dropdown-mobile-enter-active {
    transform: translateY(0%);
    opacity: 1;
  }
  &.dropdown-mobile-enter-done {
    transform: translateY(0%);
    opacity: 1;
  }
  &.dropdown-mobile-exit {
    display: none;
  }
  &.dropdown-mobile-exit-active {
    display: none;
  }
  &.dropdown-mobile-exit-done {
    display: none;
  }
`

export const MobileLinkContainer = props => (
  <Box py={4} borderBottom="1px solid" {...props} borderColor="border" />
)

export const NavDropdownButton = ({
  title,
  dropdownLinks,
  active,
  openDropdown,
  closeDropdown,
  textColor,
  ...rest
}) => {
  const buttonRef = useRef(null)
  const [buttonDimensions, setButtonDimensions] = useState(null)
  //const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    setButtonDimensions(buttonRef.current.getBoundingClientRect())
  }, [])

  return (
    <Flex
      height="100%"
      alignItems="center"
      position="relative"
      //flexDirection={"column"}
    >
      <StyledButton
        {...rest}
        onClick={() => {
          if (active === true) closeDropdown()
          else openDropdown()
        }}
        onMouseEnter={() => openDropdown()}
        ref={buttonRef}
      >
        <Text mr={"6px"} color={textColor}>
          {title}
        </Text>
      </StyledButton>
      <NavDropdownContent
        active={active}
        dropdownLinks={dropdownLinks}
        buttonDimensions={buttonDimensions && buttonDimensions}
      />
    </Flex>
  )
}

export const NavDropdownButtonMobile = ({
  title,
  dropdownLinks,
  closeDropdown,
  openDropdown,
  active,
  ...rest
}) => {
  return (
    <>
      <MobileLinkContainer bg={active && "background.1"}>
        <StyledButton
          display="flex"
          alignItems="center"
          onClick={() => {
            if (active) closeDropdown()
            else openDropdown()
          }}
        >
          <Text
            fontSize={3}
            fontWeight="semibold"
            mr={3}
            color={active ? "primary" : ""}
          >
            {title}
          </Text>
        </StyledButton>
      </MobileLinkContainer>
      <CSSTransition
        in={active}
        timeout="fast"
        classNames="dropdown-mobile"
        mountOnEnter
        unmountOnExit
      >
        <DropdownMobile
          transitionDuration="fast"
          transitionTimingFunction="ease-in"
        >
          <Box pt={7}>
            {dropdownLinks?.map((link, i) => (
              <Box pb={7} key={i}>
                <NavDropdownLink {...link} closeDropdown={closeDropdown} />
              </Box>
            ))}
          </Box>
        </DropdownMobile>
      </CSSTransition>
    </>
  )
}

export const NavDropdownContent = ({
  dropdownLinks,
  closeDropdown,
  active,
  showDropdown,
  buttonDimensions,
}) => {
  let hasTwoColumns = false
  dropdownLinks.map(link => {
    if (link.__typename === "ContentfulDropdownLinks") {
      hasTwoColumns = true
    }
  })

  return (
    <CSSTransition
      in={active}
      timeout="fast"
      classNames="dropdown"
      mountOnEnter
      unmountOnExit
    >
      <DropdownContainer
        transitionDuration="fast"
        transitionTimingFunction="ease-in"
      >
        <Box
          position={"absolute"}
          top={
            buttonDimensions && Math.floor(buttonDimensions.bottom) + 7 + "px"
          }
          left={"25px"}
          width={"0"}
          height={"0"}
          borderLeft={"20px solid transparent"}
          borderRight={"20px solid transparent"}
          borderBottom={"20px solid white"}
          zIndex={1000}
        ></Box>
        <DropdownMenu
          right={hasTwoColumns ? { lg: "-160px", xl: "-200px" } : "-1px"}
        >
          <Flex flexDirection={"row"}>
            <Flex flexDirection={"column"} mr={hasTwoColumns && 7}>
              {dropdownLinks?.map((link, i) => {
                switch (link.__typename) {
                  case "ContentfulLink":
                    return (
                      <Box style={{ whiteSpace: "nowrap" }} key={i}>
                        <NavDropdownLink
                          title={link.hrefText}
                          page={link.href || link.to}
                          closeDropdown={closeDropdown}
                        />
                      </Box>
                    )
                }
              })}
            </Flex>
            <Flex flexDirection={"column"} mr={hasTwoColumns && 7}>
              {dropdownLinks?.map((link, i) => {
                switch (link.__typename) {
                  case "ContentfulDropdownLinks":
                    return (
                      <>
                        <Box style={{ whiteSpace: "nowrap" }} key={i}>
                          <NavDropdownLink
                            title={link.dropdownLinkText}
                            closeDropdown={closeDropdown}
                            page={"/" + link.to.slug}
                          />
                        </Box>
                        {link.sublinks.map((item, i) => {
                          return (
                            <Flex
                              pl={3}
                              key={i}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                            >
                              <NavDropdownLink
                                title={item.hrefText}
                                closeDropdown={closeDropdown}
                                fontSize={"smaller"}
                                page={item.to !== null && "/" + item.to.slug}
                                arrow
                              />
                            </Flex>
                          )
                        })}
                      </>
                    )
                }
              })}
            </Flex>
          </Flex>
        </DropdownMenu>
      </DropdownContainer>
    </CSSTransition>
  )
}
