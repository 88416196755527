import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import css from "@styled-system/css"

import Image from "components/utils/Image"

import Box from "components/atoms/Box"
import { Paragraph, Title } from "components/atoms/Typography"
import { UnorderedList, OrderedList, ListItem } from "components/atoms/List"

import ArticleQuote from "components/molecules/ArticleQuote"
import Button from "components/atoms/Button"

const TextContainer = styled(Box)`
  a,
  a:visited {
    ${css({ color: "primary" })}
  }
  white-space: pre-wrap;
`

const RichText = ({ text, images, textColor, ...rest }) => {
  if (!text) return null
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Paragraph color={textColor && textColor} mb={4}>
          {children}
        </Paragraph>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Title variant="h1" mb={5}>
          {children}
        </Title>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Title variant="h2" mb={4}>
          {children}
        </Title>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Title variant="h3" mb={4}>
          {children}
        </Title>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Paragraph
          as="div"
          opacity={0.7}
          fontSize={"12px"}
          mb={{ _: 4, md: 5 }}
        >
          {children}
        </Paragraph>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Paragraph
          as="div"
          opacity={0.6}
          textAlign="center"
          mt={{ _: -4, md: -5 }}
          mb={{ _: 4, md: 5 }}
        >
          {children}
        </Paragraph>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList mb={6}>{children}</UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList mb={6}>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data?.uri} target="_blank" rel="noopener">
          {children}
        </a>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const contentType = node.data.target.sys.contentType?.sys.id
        const fields = node.data.target.fields
        if (fields) {
          Object.keys(fields).forEach(key => {
            const field = fields[key]
            if (field["en-US"]) fields[key] = field["en-US"]
          })
        }
        switch (contentType) {
          case "quote":
            return <ArticleQuote {...fields} />
          case "button":
            return <Button {...fields} mb={6} />
          case "html":
            return (
              <Box
                dangerouslySetInnerHTML={{ __html: fields.html }}
                my={{ _: 5, md: 6 }}
              />
            )
          default:
            return null
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (images && node.data.target.fields) {
          const { title, description, file } = node.data.target.fields
          // image.src has a url param that we need to strip off to match file.url
          return (
            <Box as="figure" my={{ _: 5, md: 6 }}>
              <Image
                title={title["en-US"]}
                fluid={
                  images.find(
                    image => image.fluid.src.split("?")[0] === file["en-US"].url
                  )?.fluid
                }
              />
            </Box>
          )
        }
        if (
          !images &&
          node.data.target.fields.file["en-US"].url.split(".")[0] === "//images"
        ) {
          const { file, title } = node.data.target.fields
          return (
            <Box as="figure" width={"100%"} my={{ _: 5, md: 6 }}>
              <img src={`https:${file["en-US"].url}`} alt={title["en-US"]} />
            </Box>
          )
        }
      },
    },
  }

  return (
    <TextContainer {...rest}>
      {documentToReactComponents(text.json, options)}
    </TextContainer>
  )
}

export default RichText
