import React from "react"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import { space, color, layout, compose } from "styled-system"
import css from "@styled-system/css"

import Link from "components/utils/Link"
import { Text } from "components/atoms/Typography"

const StyledLink = styled(Link)`
  ${css({ color: "text" })}
  &:visited {
    ${css({ color: "text" })}
  }
  &.active {
    ${css({ color: "primary" })}
  }
  ${compose(space, color, layout)}
`

/*
 * NavLink
 * - Used mainly for headers and footers
 * - For Gatsby Links, populates the text with the page title
 * - For external links, uses "hrefText" field
 */
const NavLink = ({
  to,
  href,
  hrefText,
  partiallyActive,
  onClick,
  textColor,
  fontWeight,
  ...rest
}) => {
  return (
    <StyledLink
      to={to}
      href={href}
      partiallyActive={partiallyActive}
      onClick={onClick}
    >
      <Text
        color={textColor || "inherit"}
        fontWeight={fontWeight && fontWeight}
        {...rest}
      >
        {hrefText}
      </Text>
    </StyledLink>
  )
}

NavLink.propTypes = {
  ...Link.strapiProps,
  ...propTypes.space,
}

export default NavLink
