import React from "react"
import GatsbyImage from "gatsby-image"

const Image = props => {
  // Normal Gatsby Image
  const { description, fluid, fixed, ...rest } = props
  return (
    <GatsbyImage
      fluid={fluid}
      fixed={fixed}
      alt={description || ""}
      {...rest}
    />
  )
}

export default Image
