import React from "react"

import Group from "components/utils/SvgGroup"

const LinkArrow = ({ color, ...rest }) => {
  return (
    <div {...rest}>
      <svg
        height="11"
        viewBox="0 0 16 11"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Group
          fill="none"
          fillRule="evenodd"
          stroke={color || "#4163f4"}
          strokeLinecap="square"
          strokeWidth="1.5"
          transform="translate(1.375 2)"
        >
          <path d="m0 3.75h12" />
          <path d="m9.625 7.5 3.75-3.75-3.75-3.75" />
        </Group>
      </svg>
    </div>
  )
}

export default LinkArrow
