import React from "react"
import PropTypes from "prop-types"
import Box from "components/atoms/Box"

const getPaddingFromString = string => {
  switch (string) {
    case "xs":
      return 6
    case "sm":
      return { _: 6, md: 8 }
    case "md":
      return { _: 7, md: 9 }
    case "lg":
      return { _: 7, md: 11 }
  }
}

const StandardPadding = ({ children, py, pt, pb, ...rest }) => {
  // Override pt and pb with py if there's nothing provided for pt or pb
  if (py && !pt) {
    pt = py
  }
  if (py && !pb) {
    pb = py
  }
  if (typeof pt === "string") {
    pt = getPaddingFromString(pt)
  }
  if (typeof pb === "string") {
    pb = getPaddingFromString(pb)
  }
  return (
    <Box
      px={{ _: 5, sm: 9, xl: 0 }}
      maxWidth={62}
      mx="auto"
      pt={pt}
      pb={pb}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default StandardPadding

StandardPadding.defaultProps = {
  py: { _: 7, md: 11 },
}

StandardPadding.propTypes = {
  children: PropTypes.node.isRequired,
  ...Box.propTypes,
}
