import React from "react"
import Path from "components/utils/Path"

const Hamburger = ({ fill }) => {
  return (
    <svg width="25" height="16" xmlns="http://www.w3.org/2000/svg">
      <Path
        fill={fill || "primary"}
        d="M0 0h25v2H0V0zM0 7h25v2H0V7zM0 14h25v2H0v-2z"
      />
    </svg>
  )
}

export default Hamburger
