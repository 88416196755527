import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import css from "@styled-system/css"
import { shadow, layout, position, compose } from "styled-system"
import { animation } from "lib/styled-system/system"
import useStoreScroll from "hooks/use-store-scroll"

import {
  NavDropdownButton,
  NavDropdownButtonMobile,
  NavDropdownContent,
} from "components/molecules/NavDropdown"

import CSSTransition from "components/utils/CSSTransition"
import ThemeSwitcher from "components/utils/ThemeSwitcher"
import Link from "components/utils/Link"
import Image from "components/utils/Image"

import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import NavLink from "components/atoms/NavLink"
import Button from "components/atoms/Button"
import { Text } from "components/atoms/Typography"

import HamburgerIcon from "components/icons/Hamburger"
import CloseIcon from "components/icons/Close"
import SmallArrowGrey from "components/icons/SmallArrowGrey"

const GlobalStyle = createGlobalStyle`
  html[data-scroll="0"] {
    .header-light {
      background-color: "transparent";
      box-shadow: none;
    }
    .header-dark {
      background-color: "transparent";
      box-shadow: none;
    }
  }
  html:not([data-scroll="0"]) {
    .header-dark {
      background-color: #000E46;
    }
    .header-light {
      background-color: #FFF;
    }
  }
`

const StyledHeader = styled("header")(
  // Static styles
  {
    transition: "background-color 0.2s ease",
    position: "fixed",
    width: "100%",
    zIndex: 1000,
    top: 0,
  },
  // Static styles that are defined by the theme
  css({
    bg: "transparent",
    height: "110px",
  }),
  // Dynamic styles that can be changed by props
  compose(shadow, position)
)

// Default for the dynamic styles
// StyledHeader.defaultProps = {
//   boxShadow: "md",
// }

const MobileLinkContainer = props => (
  <Box py={props.paddingY || 4} {...props} borderColor="border" />
)

const MenuButton = styled("button")`
  ${layout}
`

// https://stackoverflow.com/questions/42660907/using-reactcsstransitiongroup-with-styled-component
const MobileMenu = styled("div")`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  transform: translateX(100%);
  transition-property: transform;
  transition-duration: 300ms;
  ${css({
    bg: "background.0",
  })}
  ${animation}
  &.menu-enter {
    transform: translateX(100%);
  }
  &.menu-enter-active {
    transform: translateX(0%);
  }
  &.menu-enter-done {
    transform: translateX(0%);
  }
  &.menu-exit {
    transform: translateX(0%);
  }
  &.menu-exit-active {
    transform: translateX(100%);
  }
  &.menu-exit-done {
    transform: translateX(100%);
  }
`

// https://stackoverflow.com/questions/42660907/using-reactcsstransitiongroup-with-styled-component
const MobileSubMenu = styled(Flex)`
  flex-direction: column;
  margin-left: 30px;
  z-index: 2000;
  //top: 0;
  //left: 0;
  //height: 100vh;
  width: 100%;
  overflow-y: auto;
  //transform: translateY(100%);
  transition-property: transform;
  transition-duration: 300ms;
  ${css({
    bg: "transparent",
  })}
  ${animation}
  &.submenu-enter {
    transform: translateY(-10%);
  }
  &.submenu-enter-active {
    transform: translateY(0%);
  }
  &.submenu-enter-done {
    transform: translateY(0%);
  }
  &.submenu-exit {
    transform: translateY(0%);
  }
  &.submenu-exit-active {
    transform: translateY(-10%);
  }
  &.submenu-exit-done {
    transform: translateY(-10%);
  }
`

const Header = ({
  theme,
  shadow,
  logos,
  siteTitle,
  links,
  button,
  firstSectionTheme,
  pageSlug,
}) => {
  const [menu, setMenu] = useState(false)
  const [dropdown, setDropdown] = useState(null)
  const [navLinksCollapsed, setnavLinksCollapsed] = useState(null)
  const [mobileSubMenu, setMobileSubMenu] = useState(null)
  const menuBreakpoint = "lg"
  const scroll = useStoreScroll()

  useEffect(() => {
    setnavLinksCollapsed(window.innerWidth < 1024)
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setnavLinksCollapsed(true)
      }
      if (window.innerWidth >= 1024) {
        setnavLinksCollapsed(false)
      }
    }
    window.addEventListener("resize", handleResize)
    const handleScroll = () => {
      if (dropdown !== null) {
        setDropdown(null)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [dropdown, setDropdown])

  const Logo = () => (
    <Link to="/">
      <Box width={pageSlug !== "home" ? "84px" : "200px"} height={"84px"}>
        {firstSectionTheme !== "dark" && pageSlug !== "home" && (
          <Image
            {...logos[0]}
            loading="eager"
            alternativeText="RBA"
            height={"50px"}
          />
        )}
        {firstSectionTheme === "dark" && pageSlug !== "home" && (
          <Image
            {...logos[1]}
            loading="eager"
            alternativeText="RBA"
            height={"50px"}
          />
        )}
        {firstSectionTheme !== "dark" && pageSlug === "home" && (
          <Image
            {...logos[3]}
            loading="eager"
            alternativeText="RBA"
            height={"50px"}
          />
        )}
        {firstSectionTheme === "dark" && pageSlug === "home" && (
          <Image
            {...logos[2]}
            loading="eager"
            alternativeText="RBA"
            height={"50px"}
          />
        )}
      </Box>
    </Link>
  )

  const MenuLogo = () => (
    <Link to="/">
      <Box width={"84px"} height={"84px"}>
        <Image
          {...logos[0]}
          loading="eager"
          alternativeText="RBA"
          height={"50px"}
        />
      </Box>
    </Link>
  )

  const closeDropdown = () => {
    setDropdown(null)
    setMenu(false)
  }

  const toggleMobileSubMenu = idx => {
    if (mobileSubMenu === idx) {
      setMobileSubMenu(null)
    } else {
      setMobileSubMenu(idx)
    }
  }

  return (
    <>
      <ThemeSwitcher theme={theme}>
        <GlobalStyle />
        <Box position="relative" pb={"94px"} bg={"transparent"}>
          <StyledHeader
            className={
              firstSectionTheme === "dark" ? "header-dark" : "header-light"
            }
            boxShadow={shadow}
            data-scroll-header
            onClick={dropdown !== null && closeDropdown}
          >
            <StandardPadding py={0} height="100%">
              <Flex
                as="nav"
                alignItems="center"
                justifyContent="space-between"
                height="100%"
              >
                <Logo />
                {!navLinksCollapsed && (
                  <Box
                    display={{ _: "none", [menuBreakpoint]: "flex" }}
                    height="100%"
                    justifySelf={"flex-end"}
                  >
                    {links.map((data, i) => {
                      switch (data.__typename) {
                        case "ContentfulDropdownLinks":
                          return (
                            <NavDropdownButton
                              mx={3}
                              closeDropdown={closeDropdown}
                              openDropdown={() => setDropdown(i)}
                              active={dropdown === i}
                              dropdown={dropdown}
                              key={i}
                              title={data.dropdownLinkText}
                              dropdownLinks={data.sublinks}
                              textColor={
                                firstSectionTheme === "dark"
                                  ? "white"
                                  : "primary"
                              }
                            />
                          )
                        default:
                          return (
                            <Flex alignItems="center" key={i}>
                              <NavLink
                                mr={4}
                                partiallyActive={true}
                                onMouseEnter={() => closeDropdown()}
                                onClick={closeDropdown}
                                {...data}
                                textColor={
                                  firstSectionTheme === "dark"
                                    ? "white"
                                    : "primary"
                                }
                              />
                            </Flex>
                          )
                      }
                    })}
                  </Box>
                )}
                {navLinksCollapsed && (
                  <>
                    <Box
                      alignItems="center"
                      display={{ _: "none", [menuBreakpoint]: "flex" }}
                    >
                      {button && (
                        <Button
                          {...button}
                          ml={5}
                          minWidth={9}
                          height="40px"
                          onClick={closeDropdown}
                        />
                      )}
                    </Box>

                    <MenuButton
                      onClick={() => setMenu(true)}
                      width={2}
                      height={2}
                      display={{ _: "block", [menuBreakpoint]: "none" }}
                    >
                      <HamburgerIcon
                        fill={
                          firstSectionTheme === "dark" ? "white" : "primary"
                        }
                      />
                    </MenuButton>
                  </>
                )}
              </Flex>
            </StandardPadding>
          </StyledHeader>
          {dropdown !== null && (
            <Box // overlay to close the dropdown
              position="fixed"
              top={0}
              width="100%"
              height="100%"
              zIndex={99}
              onClick={closeDropdown}
            />
          )}
        </Box>

        <CSSTransition
          in={menu}
          timeout="md"
          classNames="menu"
          mountOnEnter
          unmountOnExit
        >
          <MobileMenu
            // transitionDuration="md"
            transitionTimingFunction="ease-in-out"
          >
            <StyledHeader as="div" boxShadow="none" position="static">
              <StandardPadding py={0} height="100%">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  height="100%"
                >
                  <MenuLogo />
                  <MenuButton
                    onClick={() => {
                      setDropdown(null)
                      setMenu(false)
                    }}
                    width={2}
                    height={2}
                  >
                    <CloseIcon fill="text" />
                  </MenuButton>
                </Flex>
              </StandardPadding>
            </StyledHeader>
            <StandardPadding py={0}>
              <Box
                flexDirection="column"
                alignItems="center"
                mt={6}
                pb={mobileSubMenu !== null && "200px"}
              >
                <MobileLinkContainer
                //borderTop="1px solid"
                >
                  <NavLink
                    fontSize={3}
                    partiallyActive={true}
                    to="/"
                    hrefText="Home"
                  />
                </MobileLinkContainer>
                {links.map((data, i) => {
                  switch (data.__typename) {
                    case "ContentfulDropdownLinks":
                      return (
                        <>
                          <MobileLinkContainer key={i}>
                            <button onClick={() => toggleMobileSubMenu(i)}>
                              <Text fontSize={3}>{data.dropdownLinkText}</Text>
                            </button>
                          </MobileLinkContainer>
                          <CSSTransition
                            in={mobileSubMenu === i}
                            timeout="lg"
                            classNames="submenu"
                            mountOnEnter
                            unmountOnExit
                          >
                            <MobileSubMenu>
                              {data.sublinks.map(sublink => {
                                switch (sublink.__typename) {
                                  case "ContentfulDropdownLinks":
                                    return (
                                      <>
                                        <MobileLinkContainer paddingY={4}>
                                          <NavLink
                                            fontSize={2}
                                            partiallyActive={true}
                                            onClick={closeDropdown}
                                            {...sublink}
                                            hrefText={sublink.dropdownLinkText}
                                          />
                                        </MobileLinkContainer>
                                        <Flex ml={5} flexDirection="column">
                                          {sublink.sublinks.map(item => (
                                            <MobileLinkContainer paddingY={3}>
                                              <Flex flexDirection={"row"}>
                                                <Box mr={3}>
                                                  <NavLink
                                                    fontSize={1}
                                                    partiallyActive={true}
                                                    onClick={closeDropdown}
                                                    {...item}
                                                    fontWeight={300}
                                                  />
                                                </Box>
                                                <SmallArrowGrey />
                                              </Flex>
                                            </MobileLinkContainer>
                                          ))}
                                        </Flex>
                                      </>
                                    )
                                  default:
                                    return (
                                      <MobileLinkContainer paddingY={4}>
                                        <NavLink
                                          fontSize={2}
                                          partiallyActive={true}
                                          onClick={closeDropdown}
                                          //{...data}
                                          {...sublink}
                                          // hrefText={sublink.dropdownLinkText}
                                        />
                                      </MobileLinkContainer>
                                    )
                                }
                              })}
                            </MobileSubMenu>
                          </CSSTransition>
                        </>
                      )
                    default:
                      return (
                        <MobileLinkContainer key={i}>
                          <NavLink
                            fontSize={3}
                            partiallyActive={true}
                            onClick={closeDropdown}
                            {...data}
                          />
                        </MobileLinkContainer>
                      )
                  }
                })}
                {button && (
                  <Button
                    {...button}
                    mt={5}
                    minWidth={9}
                    height="40px"
                    onClick={closeDropdown}
                  />
                )}
              </Box>
            </StandardPadding>
          </MobileMenu>
        </CSSTransition>
      </ThemeSwitcher>
    </>
  )
}

export default Header

export const query = graphql`
  fragment Header on ContentfulHeader {
    logos {
      fluid(maxWidth: 240, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    links {
      ...Link
      ... on ContentfulDropdownLinks {
        dropdownLinkText
        sublinks {
          ...Link
          ... on ContentfulDropdownLinks {
            dropdownLinkText
            to {
              slug
              link {
                ...Link
              }
            }
            sublinks {
              ...Link
            }
          }
        }
      }
    }
    button {
      ...Button
    }
  }
`
