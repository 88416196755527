import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Box from "components/atoms/Box"

const StyledImg = styled("img")({
  height: "100%",
  objectFit: "contain",
})

/*
 * An SVG Icon on Strapi
 * - Note that icons are just normal images uploaded to strapi in SVG format
 * - This is a separate component because Gatsby Image can't be used with SVGs
 *   and also allows consistent styling of icons
 * - At some point we may want to embed this directly in the html so we can style it
 */
const Icon = ({ file, description, size, ...rest }) => {
  let sizeProps = {}
  switch (size) {
    case "xxs":
      sizeProps.width = "12px"
      sizeProps.height = "12px"
      break
    case "xs":
      sizeProps.width = 1
      sizeProps.height = 1
      break
    case "small":
      sizeProps.width = 2
      sizeProps.height = 2
      break
    case "medium":
      sizeProps.width = 3
      sizeProps.height = 3
      break
    case "large":
      sizeProps.width = 4
      sizeProps.height = 4
      break
    default:
  }
  return (
    <Box {...sizeProps} {...rest}>
      {file && <StyledImg src={file.url} alt={description || ""} />}
    </Box>
  )
}

export default Icon

Icon.defaultProps = {
  size: "medium",
}

Icon.strapiProps = {
  file: PropTypes.object.isRequired,
  description: PropTypes.string,
}

Icon.propTypes = {
  ...Icon.strapiProps,
  ...Box.propTypes,
}

export const query = graphql`
  fragment Icon on ContentfulAsset {
    file {
      url
    }
    description
  }
`
