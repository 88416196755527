import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const author = "@NoyoHQ"

function SEO({
  // siteTitle,
  metaDescription = "",
  favicon,
  meta,
  title,
  image,
  noIndex,
}) {
  const twitterImage = image?.replace("//", "http://")
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      // titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={favicon?.url || "favicon.png"}
      />
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
