import styled from "styled-components"
import { space, layout, compose } from "styled-system"
import { animation } from "lib/styled-system/system"

const Svg = styled("svg")`
  vertical-align: middle;
  ${compose(space, animation)}
`

export default Svg
