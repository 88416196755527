import React from "react"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"

import { Title, Paragraph } from "components/atoms/Typography"

const ArticleQuote = ({ quote, name, subtext }) => {
  return (
    <Flex as="figure" ml={{ _: 3, md: 7 }} my={{ _: 6, md: 7 }}>
      <Title as="span" variant="h1" fontSize={6} lineHeight="inherit" mr={4}>
        “
      </Title>
      <Box maxWidth={32}>
        <Box as="blockquote" m={0}>
          <Title variant="h2" as="p" mb={3}>
            {quote}
          </Title>
        </Box>
        <Paragraph as="figcaption" fontWeight="semibold">
          - {name}, {subtext}
        </Paragraph>
      </Box>
    </Flex>
  )
}

export default ArticleQuote
